import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import { is } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import 'firebase/firestore';
import 'firebase/storage';
import moment from 'moment';
import 'moment/locale/is';
import React from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createFirestoreInstance } from 'redux-firestore';
import configureStore from '../src/configureStore';
import './App.css';
import './index.css';
import clientConfig from './client-config';
import { AudioPlayer } from './components/audio-player/AudioPlayer';
import { ModalRoot } from './components/modal/ModalRoot';
import firebaseConfig from './firebaseConfig';
import EditPodcast from './pages/EditPodcast';
import { ForgotPassword } from './pages/ForgotPassword';
import Homepage from './pages/Homepage';
import { Login } from './pages/Login';
import ManagePodcastAnalytics from './pages/ManagePodcastAnalytics';
import ManagePodcastEmail from './pages/ManagePodcastEmail';
import ManagePodcastGeneral from './pages/ManagePodcastGeneral';
import ManagePodcastLive from './pages/ManagePodcastLive';
import ManagePodcastNewSubscription from './pages/ManagePodcastNewSubscription';
import ManagePodcastUploads from './pages/ManagePodcastUploads';
import ManagePodcastUsers from './pages/ManagePodcastUsers';
import NewPodcast from './pages/NewPodcast';
import PardusRssAdminDetail from './pages/PardusRssAdminDetail';
import PardusRssPage from './pages/PardusRssPage';
import PodcastDetail from './pages/PodcastDetail';
import PodcastDetailLive from './pages/PodcastDetailLive';
import PodcastPurchaseLogin from './pages/PodcastPurchaseLogin';
import PodcastPurchasePayment from './pages/PodcastPurchasePayment';
import Podcasts from './pages/Podcasts';
import PodcastUploadsEdit from './pages/PodcastUploadsEdit';
import PodcastUploadsNew from './pages/PodcastUploadsNew';
import Privacy from './pages/Privacy';
import { Register } from './pages/Register';
import RegisteredUsers from './pages/RegisteredUsers';
import Subscriptions from './pages/Subscriptions';
import { ForgotPasswordVisir } from './pages/visir/ForgotPasswordVisir';
import HomepageVisir from './pages/visir/HomepageVisir';
import { LoginVisir } from './pages/visir/LoginVisir';
import PodcastDetailVisir from './pages/visir/PodcastDetailVisir';
import { RegisterVisir } from './pages/visir/RegisterVisir';
import { saveState } from './utils/localStorage';
import { PrivateRoute } from './utils/PrivateRoute';
import FaqPage from './pages/FaqPage';
import PrivacyVisir from './pages/visir/PrivacyVisir';
import AdminPage from './pages/AdminPage';
import NewCategory from './pages/admin/NewCategory';
import EditCategory from './pages/admin/EditCategory';
import ManageCategoryPage from './pages/ManageCategoryPage';

const persistedState = {};
const store = configureStore({});

store.subscribe(() => {
  saveState({
    player: store.getState().player,
  });
});

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};
if (!firebase.apps?.length) {
  firebase.initializeApp(firebaseConfig);
}
firebase.firestore();

function App() {
  moment.locale('is');
  setDefaultOptions({ locale: is });
  let cachedTimes = null;
  if (localStorage?.getItem('time')) {
    cachedTimes = { ...JSON.parse(localStorage.getItem('time') as string) };
  }
  const clientComponents: any = {
    homepage: {
      visir: HomepageVisir,
      main: Homepage,
    },
    register: {
      visir: RegisterVisir,
      main: Register,
    },
    login: {
      visir: LoginVisir,
      main: Login,
    },
    forgot: {
      visir: ForgotPasswordVisir,
      main: ForgotPassword,
    },
    detail: {
      visir: PodcastDetailVisir,
      main: PodcastDetail,
    },
    privacy: {
      visir: PrivacyVisir,
      main: Privacy,
    },
  };
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/"
              component={clientComponents.homepage[clientConfig.name]}
            />
            <Route
              exact
              path="/privacy"
              component={clientComponents.privacy[clientConfig.name]}
            />
            <Route
              exact
              path="/login"
              component={clientComponents.login[clientConfig.name]}
            />
            <Route
              exact
              path="/register"
              component={clientComponents.register[clientConfig.name]}
            />
            <Route
              exact
              path="/forgot"
              component={clientComponents.forgot[clientConfig.name]}
            />
            <Route
              exact
              path={'/podcasts/purchase/:podcastId'}
              component={PodcastPurchaseLogin}
            />
            {clientConfig.name === 'visir' ? (
              <Route
                exact
                path={'/podcasts/payment/:podcastId'}
                component={PodcastPurchasePayment}
              />
            ) : (
              <PrivateRoute
                exact
                path={'/podcasts/payment/:podcastId'}
                component={PodcastPurchasePayment}
              />
            )}
            <Route
              exact
              path={'/podcasts/detail/:podcastId/live'}
              component={PodcastDetailLive}
            />
            <PrivateRoute exact path={'/podcasts'} component={Podcasts} />
            <PrivateRoute
              exact
              path={'/subscriptions'}
              component={Subscriptions}
            />
            <PrivateRoute exact path={'/podcasts/new'} component={NewPodcast} />
            <PrivateRoute
              exact
              path={'/podcasts/edit/:podcastId'}
              component={EditPodcast}
            />
            <PrivateRoute
              exact
              path={'/podcasts/manage/:podcastId'}
              component={ManagePodcastGeneral}
            />
            <PrivateRoute
              exact
              path={'/podcasts/uploads/:podcastId'}
              component={ManagePodcastUploads}
            />
            <PrivateRoute
              exact
              path={'/podcasts/uploads/:podcastId/new'}
              component={PodcastUploadsNew}
            />
            <PrivateRoute
              exact
              path={'/episodes/:episodeId/edit'}
              component={PodcastUploadsEdit}
            />
            <PrivateRoute
              exact
              path={'/podcasts/analytics/:podcastId'}
              component={ManagePodcastAnalytics}
            />
            <PrivateRoute
              exact
              path={'/podcasts/live/:podcastId'}
              component={ManagePodcastLive}
            />
            <PrivateRoute
              exact
              path={'/podcasts/email/:podcastId'}
              component={ManagePodcastEmail}
            />
            <PrivateRoute
              exact
              path={'/podcasts/users/:podcastId'}
              component={ManagePodcastUsers}
            />
            <PrivateRoute
              exact
              path={'/podcasts/subscriptions/:podcastId/new'}
              component={ManagePodcastNewSubscription}
            />
            <PrivateRoute
              exact
              path={'/registeredusers'}
              component={RegisteredUsers}
            />
            <PrivateRoute exact path={'/rss'} component={PardusRssPage} />
            <PrivateRoute
              exact
              path={'/rss/:userId'}
              component={PardusRssAdminDetail}
            />
            <PrivateRoute exact path={'/admin'} component={AdminPage} />
            <PrivateRoute
              exact
              path={'/admin/new-category'}
              component={NewCategory}
            />
            <PrivateRoute
              exact
              path={'/admin/edit-category/:categoryId'}
              component={EditCategory}
            />
            <PrivateRoute
              exact
              path={'/admin/manage-category/:categoryId'}
              component={ManageCategoryPage}
            />
            <Route exact path={'/algengar-spurningar'} component={FaqPage} />
            <Route
              exact
              path={'/:slug'}
              component={clientComponents.detail[clientConfig.name]}
            />
          </Switch>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
      <ReactNotifications />
      <ModalRoot />
      <AudioPlayer cachedTimes={cachedTimes} />
    </Provider>
  );
}

export default App;
