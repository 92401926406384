import { call } from './../utils/ApiManager';
import { FrontCategory } from './../types';
import firebase from 'firebase';

export default class CategoryBusiness {
  constructor(private db: firebase.firestore.Firestore) {}

  async getActiveCategories() {
    return call('/front-categories')();
  }

  async create(cat: FrontCategory) {
    return call('/front-categories')({ name: cat.name, layout: cat.layout });
  }

  async update(cat: FrontCategory) {
    return this.db.collection('frontcategories').doc(cat.id).update({
      name: cat.name,
      layout: cat.layout,
    });
  }

  async delete(id: string) {
    return call('/front-categories/' + id, false, null, 'DELETE')();
  }
  async updateOrdinal(ids: string[]) {
    return call('/front-categories/update-ordinal')({ ids });
  }
}
