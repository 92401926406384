import React from 'react';
import s from './FeedPost.module.css';
import { FeedPostAttachment } from '../post-to-feed/FeedAttachments';
import moment from 'moment';
import { PodcastFeedComments } from '../podcast-feed-comments/PodcastFeedComments';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import FeedBusiness from '../../business/FeedBusiness';
import { useTypedSelector } from '../../redux/reducers';
import { isLoaded, useFirestore } from 'react-redux-firebase';
import cn from 'classnames';
import { showModal } from '../../redux/actions/modal';
import { ModalType } from '../../types';
import { LockedTop } from './LockedTop';
import { CollapsableText } from './CollapsableText';
import { FeedAttachments } from './FeedAttachments';

type Props = {
  podcastId: string;
  attachments?: { blurredUrl: string }[] | FeedPostAttachment[];
  isLocked?: boolean;
  createdAt: firebase.firestore.Timestamp;
  content: string;
  isEmbedCode?: boolean;
  allowComments?: boolean;
  feedId?: string;
  authorId?: string;
  slug: string;
};

export const FeedPost: React.FC<Props> = ({
  podcastId,
  slug,
  attachments,
  isLocked,
  createdAt,
  content,
  isEmbedCode,
  allowComments,
  feedId,
  authorId,
}) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const removeFeed = () =>
    dispatch(
      showModal(ModalType.CONFIRM_MODAL, {
        title: 'Fjarlægja færslu',
        actionText: 'Já',
        action: async () => {
          const feedBusiness: FeedBusiness = new FeedBusiness(
            firestore,
            auth.uid
          );
          await feedBusiness.remove(feedId as string, podcastId);
        },
        body: 'Viltu eyða þessari færslu?',
      })
    );

  return (
    <div className={s.postContainer}>
      <div className={s.post}>
        {isLocked && !!attachments?.length && (
          <LockedTop podcastId={podcastId} image={attachments[0].blurredUrl} />
        )}
        {!isLocked && !!attachments?.length && (
          <FeedAttachments attachments={attachments as FeedPostAttachment[]} />
        )}
        <div
          className={cn(s.content, { [s.noAttachment]: !attachments?.length })}
        >
          <div className={s.meta}>
            <div>
              {moment(createdAt?.toDate?.() || createdAt).format(
                'DD MMMM YYYY'
              )}
            </div>
            {isLoaded(auth) &&
              !auth.isEmpty &&
              (auth?.uid === authorId || profile?.isAdmin) && (
                <div>
                  <span className={s.removeFeed} onClick={() => removeFeed()}>
                    Fjarlægja
                  </span>
                </div>
              )}
          </div>
          {!isEmbedCode && (
            <div className={s.contentText}>
              <CollapsableText
                content={content}
                podcastId={podcastId}
                isLocked={isLocked}
              />
            </div>
          )}
          {allowComments && (
            <PodcastFeedComments
              slug={slug}
              podcastId={podcastId}
              feedId={feedId as string}
            />
          )}
        </div>
      </div>
    </div>
  );
};
