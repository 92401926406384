import React from 'react';
import { useTypedSelector } from '../../redux/reducers';
import { ModalType } from '../../types';
import { CropperModal } from './CropperModal';
import ConfirmModal from './ConfirmModal';
import { UsernameModal } from './UsernameModal';
import BasicModal from './BasicModal';
import PaymentModal from './PaymentModal';
import { PodcastEpisodesModal } from './PodcastEpisodesModal';
import { PrivacyModal } from './PrivacyModal';
import { VimeoUrlModal } from './VimeoUrlModal';
import { SelectOptionModal } from './SelectOptionModal';
import { CreateRssLinkModal } from './CreateRSSLinkModal';
import { KlippariUrlModal } from './KlippariUrlModal';
import { EmbedModal } from "./EmbedModal";

const MODAL_COMPONENTS: { [key: string]: any } = {};

MODAL_COMPONENTS[ModalType.CROPPER_MODAL] = CropperModal;
MODAL_COMPONENTS[ModalType.CONFIRM_MODAL] = ConfirmModal;
MODAL_COMPONENTS[ModalType.USERNAME_MODAL] = UsernameModal;
MODAL_COMPONENTS[ModalType.BASIC_MODAL] = BasicModal;
MODAL_COMPONENTS[ModalType.PAYMENT_MODAL] = PaymentModal;
MODAL_COMPONENTS[ModalType.EPISODES_MODAL] = PodcastEpisodesModal;
MODAL_COMPONENTS[ModalType.PRIVACY_MODAL] = PrivacyModal;
MODAL_COMPONENTS[ModalType.VIMEO_URL] = VimeoUrlModal;
MODAL_COMPONENTS[ModalType.KLIPPARI_URL] = KlippariUrlModal;
MODAL_COMPONENTS[ModalType.SELECT_OPTION] = SelectOptionModal;
MODAL_COMPONENTS[ModalType.CREATE_RSS_LINK] = CreateRssLinkModal;
MODAL_COMPONENTS[ModalType.EMBED_MODAL] = EmbedModal;

export const ModalRoot: React.FC = () => {
  const { modalType, modalProps } = useTypedSelector((state) => state.modal);
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};
