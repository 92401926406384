import firebase from 'firebase';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { call } from '../../utils/ApiManager';
import { Button } from '../button/Button';
import { Spinner } from '../spinner/Spinner';
import s from './FeedPreview.module.css';
import { FeedPost } from '../feed-post/FeedPost';
type Props = {
  podcastId: string;
  slug: string;
};
export const FeedPreview: FC<Props> = ({ podcastId, slug }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState<
    {
      createdAt: firebase.firestore.Timestamp;
      content: string;
      attachments: { blurredUrl: string }[];
    }[]
  >([]);
  const getPosts = async () => {
    setIsLoading(true);
    const response = await call(`/podcasts/${podcastId}/lockedfeed`, true)();
    setPosts(response.posts);
    setIsLoading(false);
  };
  useEffect(() => {
    getPosts();
  }, []);
  if (isLoading) {
    return <Spinner />;
  }
  if (!posts?.length) {
    return null;
  }
  return (
    <div className={s.container}>
      <h2 className={s.feedTitle}>Nýlegar færslur</h2>
      {posts?.map((post) => (
        <FeedPost
          slug={slug}
          podcastId={podcastId}
          attachments={post.attachments}
          isLocked={true}
          createdAt={
            new firebase.firestore.Timestamp(
              (post.createdAt as any)._seconds || 0,
              (post.createdAt as any)._nanoseconds || 0
            )
          }
          content={post.content}
        />
      ))}
      {posts?.length > 0 && (
        <div className={s.seeMore}>
          <Link to={'/podcasts/purchase/' + podcastId}>
            Skoða fleiri færslur
          </Link>
        </div>
      )}
    </div>
  );
};
