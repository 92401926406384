import React from 'react';
import s from './EpisodeItem.module.css';
import { Episode, ModalType, Podcast } from '../../types';
import cn from 'classnames';
import moment from 'moment';
import { togglePlay } from '../../redux/actions/player';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { ImEmbed2 } from 'react-icons/im';
import { showModal } from '../../redux/actions/modal';
import clientConfig from '../../client-config';
type Props = {
  episode: Episode;
  podcast: Podcast;
  isPlaying?: boolean;
  isFeedLocked?: boolean;
  showEmbed?: boolean;
};
export const EpisodeItem: React.FC<Props> = ({
  episode,
  podcast,
  isFeedLocked,
  isPlaying,
  showEmbed,
}) => {
  const dispatch = useDispatch();
  let createdAt;
  if (episode.displayDate) {
    const { _seconds, _nanoseconds } = episode.displayDate as any;
    createdAt = new firebase.firestore.Timestamp(
      _seconds,
      _nanoseconds
    ).toDate();
  } else {
    createdAt = new Date(episode.pubDate as string);
  }
  const history = useHistory();
  return (
    <div
      className={cn(s.container, {
        [s.episodePlaying]: isPlaying,
        [s.locked]: !isFeedLocked && !episode.url,
      })}
      onClick={() => {
        if (episode.url) {
          dispatch(togglePlay(episode, podcast));
        } else if (isFeedLocked) {
          history.push('/podcasts/purchase/' + podcast.id);
        }
      }}
    >
      <div className={s.left}>
        {
          <div
            className={s.img}
            style={{
              backgroundImage: `url('${episode.img || podcast.image}?h=56')`,
            }}
          />
        }
        <div className={s.info}>
          <div className={s.name}>{episode.name}</div>
          <div className={s.meta}>
            <div>{moment(createdAt).format('DD MMM YYYY')}</div>
            {showEmbed && clientConfig.name === 'visir' && (
              <>
                <div>·</div>
                <button
                  className={
                    'tw-flex tw-items-center tw-gap-1 hover:tw-underline'
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      showModal(ModalType.EMBED_MODAL, {
                        episodeId: episode.id,
                        podcastId: podcast.id,
                      })
                    );
                  }}
                >
                  <ImEmbed2 />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
