import React, { useState } from 'react';
import firebase from 'firebase';
import s from './PodcastPlaylist.module.css';
import { Episode, Podcast } from '../../types';
import moment from 'moment';
import classNames from 'classnames';
import { create } from 'domain';
import { useTypedSelector } from '../../redux/reducers';
import { useDispatch } from 'react-redux';
import { togglePlay } from '../../redux/actions/player';
import { useWindowSize } from '../../hooks/use-window.size';
type Props = {
  title: string;
  description?: string | any;
  episodes: Episode[];
  podcast: Podcast;
};

const EpisodeItem: React.FC<{
  episode: Episode;
  isLocked: boolean;
  isPlaying: boolean;
  onClick: (episode: Episode) => void;
}> = ({ episode, isLocked, isPlaying, onClick }) => {
  const getDate = (episode: Episode) => {
    try {
      let createdAt;
      if (episode.displayDate) {
        const { _seconds, _nanoseconds } = episode.displayDate as any;
        createdAt = new firebase.firestore.Timestamp(
          _seconds,
          _nanoseconds
        ).toDate();
      } else {
        createdAt = new Date(episode.pubDate as string);
      }
      return (
        <div className={s.date}>
          {moment(createdAt).format('DD. MMMM YYYY HH:mm')}
        </div>
      );
    } catch (err: any) {
      return null;
    }
  };
  return (
    <div
      className={classNames({
        [s.episodeItem]: true,
        [s.locked]: isLocked,
        [s.playing]: isPlaying,
      })}
      onClick={() => {
        if (!isLocked) {
          onClick(episode);
        }
      }}
    >
      <div className={s.episodeName}>{episode.name}</div>
      {getDate(episode)}
    </div>
  );
};

export const PodcastPlaylist: React.FC<Props> = ({
  title,
  description,
  episodes,
  podcast,
}) => {
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const shouldCollapse = width < 640 && episodes?.length > 1;
  const [isCollapsed, setIsCollapsed] = useState(shouldCollapse);
  const { isPlaying } = useTypedSelector((state) => state.player);
  const playingEpisode = useTypedSelector((state) => state.player)?.episode;
  return !!episodes?.length ? (
    <div className={s.container}>
      <div className={s.title}>{title}</div>
      {description && <div className={s.description}>{description}</div>}
      {shouldCollapse && !isCollapsed && (
        <div style={{ textAlign: 'center' }}>
          <button
            className={s.toggleCollapse}
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            {isCollapsed ? 'Sýna alla þætti' : 'Fela'}
          </button>
        </div>
      )}
      <div
        className={classNames({
          [s.playlist]: true,
          [s.single]: episodes?.length === 1,
        })}
      >
        {episodes?.map((episode, i) => {
          if (shouldCollapse && isCollapsed && i > 0) {
            return null;
          }
          return (
            <EpisodeItem
              key={episode.id}
              episode={episode}
              isLocked={!episode.url}
              onClick={(episode: Episode) =>
                dispatch(togglePlay(episode, podcast))
              }
              isPlaying={isPlaying && episode.id === playingEpisode?.id}
            />
          );
        })}
      </div>
      {shouldCollapse && isCollapsed && (
        <div className={s.showAll} onClick={() => setIsCollapsed(false)}>
          Sýna alla þætti
        </div>
      )}
    </div>
  ) : null;
};
