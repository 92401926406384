import { Episode, Podcast, Product, SubscriptionPlan, User } from '../types';
import firebase from 'firebase';
import moment from 'moment';
import { call } from '../utils/ApiManager';
import { error } from '../utils/Notifications';
import clientConfig from '../client-config';

export default class SubscriptionBusiness {
  constructor(private db: firebase.firestore.Firestore) {}

  async create(email: string, podcastId: string, plan: SubscriptionPlan) {
    const usersRef = await this.db
      .collection('users')
      .where('email', '==', email)
      .get();
    if (!usersRef.size) {
      throw new Error('Notandi fannst ekki í kerfinu.');
    }
    const podcastRef = await this.db
      .collection('podcasts')
      .doc(podcastId)
      .get();
    const podcast = podcastRef.data() as Podcast;
    await this.db.collection('podcastsubscriptions').add({
      manuallyAdded: true,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      endsAt: plan?.days
        ? moment()
            .add(plan?.days || 1, 'days')
            .toDate()
        : moment()
            .add(plan?.months || 1, 'months')
            .toDate(),
      podcastId,
      userId: usersRef.docs[0].id,
      ownerId: podcast.userId,
      userEmail: (usersRef.docs[0].data() as User).email,
      subscriptionPlan: plan,
      client: clientConfig.name || 'main',
    });
  }

  async removeTimeLimit(
    subscriptionId: string,
    subscriptionPlan: SubscriptionPlan
  ) {
    const subRef = this.db
      .collection('podcastsubscriptions')
      .doc(subscriptionId);
    if (!subRef) {
      throw new Error('Áskriftin fannst ekki.');
    }
    await subRef.update({
      subscriptionPlan: { ...subscriptionPlan, timeLimit: null },
    });
  }

  async createPremium(email: string, product: Product, plan: SubscriptionPlan) {
    const usersRef = await this.db
      .collection('users')
      .where('email', '==', email)
      .get();
    if (!usersRef.size) {
      throw new Error('Notandi fannst ekki í kerfinu.');
    }
    await this.db.collection('productsubscriptions').add({
      manuallyAdded: true,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      endsAt: plan?.days
        ? moment()
            .add(plan?.days || 1, 'days')
            .toDate()
        : moment()
            .add(plan?.months || 1, 'months')
            .toDate(),
      userId: usersRef.docs[0].id,
      userEmail: (usersRef.docs[0].data() as User).email,
      subscriptionPlan: plan,
      productId: product.id,
      preventRenewal: product.subscriptionType !== 'RECURRING',
      subscriptionType: product.subscriptionType,
      productType: product.productType,
    });
  }

  async stopSubscription(subId: string): Promise<void> {
    try {
      await call(`/subscriptions/${subId}/stop`, false, null, 'POST')();
    } catch (err: any) {
      console.error(err);
      error(err.message || 'Óvænt villa kom upp. Reyndu aftur síðar.');
    }
  }

  async activateSubscription(subId: string): Promise<void> {
    try {
      await call(`/subscriptions/${subId}/activate`, false, null, 'POST')();
    } catch (err: any) {
      console.error(err);
      error(err.message || 'Óvænt villa kom upp. Reyndu aftur síðar.');
    }
  }

  async toggleNotifications(subId: string): Promise<void> {
    try {
      await call(
        `/subscriptions/${subId}/togglenotifications`,
        false,
        null,
        'POST'
      )();
    } catch (err: any) {
      console.error(err);
      error(err.message || 'Óvænt villa kom upp. Reyndu aftur síðar.');
    }
  }
}
