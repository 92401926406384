import firebase from 'firebase';
import { FeedPostAttachment } from './components/post-to-feed/FeedAttachments';
import moment from 'moment';

export type ModalAction = {
  type: ModalActionType;
  modalType?: ModalType;
  modalProps?: any;
};

export enum ModalActionType {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
}

export enum ModalType {
  CROPPER_MODAL = 'CROPPER_MODAL',
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  USERNAME_MODAL = 'USERNAME_MODAL',
  BASIC_MODAL = 'BASIC_MODAL',
  PAYMENT_MODAL = 'PAYMENT_MODAL',
  EPISODES_MODAL = 'EPISODES_MODAL',
  PRIVACY_MODAL = 'PRIVACY_MODAL',
  VIMEO_URL = 'VIMEO_URL',
  KLIPPARI_URL = 'KLIPPARI_URL',
  SELECT_OPTION = 'SELECT_OPTION',
  CREATE_RSS_LINK = 'CREATE_RSS_LINK',
  EMBED_MODAL = 'EMBED_MODAL'
}

export type ModalState = {
  modalType: ModalType | null;
  modalProps?: any;
};

export type PlayerAction = {
  type: 'TOGGLE_PLAY' | 'HIDE' | 'STOP' | 'START';
  episode?: Episode;
  podcast?: Podcast;
  timeCheckPoint?: number;
};

export type PlayerState = {
  isPlaying: boolean;
  episode?: Episode;
  podcast?: Podcast;
};

export type LinkItem = {
  to?: string;
  title: string;
  onClick?: any;
};
export enum FrontCategoryLayout {
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
}
export type FrontCategory = {
  id?: string;
  active: boolean;
  layout: FrontCategoryLayout;
  name: string;
  ordinal: number;
  podcasts: string[];
};
export type Podcast = {
  id?: string;
  name?: string;
  hideTitleOnDetailPage?: boolean;
  price?: string;
  userId?: string;
  status?: 'DRAFT' | 'PUBLISHED';
  active?: boolean;
  description?: string;
  rss?: string;
  image?: string;
  cover?: string;
  createdAt?: firebase.firestore.Timestamp;
  userEmail?: string;
  feedCount?: number;
  subscriptionPlans?: SubscriptionPlan[];
  shortDescription?: string;
  subscriptionDescription?: string;
  ordinal?: number;
  eventButton?: {
    url: string;
    text: string;
    visible: boolean;
  };
  slug?: string;
  oneTimePurchase?: boolean;
  deadline?: firebase.firestore.Timestamp | null;
  categories?: string[];
  isLiveStreaming?: boolean;
  lastLiveStreamingStart?: firebase.firestore.Timestamp;
  disableLiveStreamComments?: boolean;
  tallyPoll?: { id: string; title: string; visible: boolean };
  expiration?: {
    from: firebase.firestore.Timestamp;
    to: firebase.firestore.Timestamp;
  };
  paused?: boolean;
};

export type PodcastCategory = {
  count: number;
  title: string;
};

export type SubscriptionPlan = {
  id: string;
  months?: number;
  price: number;
  days?: number;
  timeLimit?: {
    unit: 'months' | 'days';
    value: number;
  };
};

export enum ManagePodcastTab {
  GENERAL = 'GENERAL',
  UPLOADS = 'UPLOADS',
  ANALYTICS = 'ANALYTICS',
  USERS = 'USERS',
  LIVE = 'LIVE',
  EMAIL = 'EMAIL',
}

export type Episode = {
  id?: string;
  name?: string;
  url?: string;
  img?: string;
  displayDate?: firebase.firestore.Timestamp;
  createdAt?: firebase.firestore.Timestamp;
  podcastId?: string;
  active?: boolean;
  pubDate?: string;
  isOpen?: boolean;
  played?: number;
  description?: string;
  totalPlayed?: number;
};
export type Subscription = {
  id?: string;
  borgunToken?: {
    PAN: string;
  };
  createdAt: firebase.firestore.Timestamp;
  endsAt: firebase.firestore.Timestamp;
  userId: string;
  podcastId: string;
  authcode?: string;
  reference?: string;
  userEmail?: string;
  recurringToken?: string;
  preventRenewal?: boolean;
  lastRenewal?: firebase.firestore.Timestamp;
  subscriptionPlan: SubscriptionPlan;
  renewals?: string[];
  disableCreatorNotifications?: boolean;
  client?: 'main' | 'visir';
};
export type FeedType = 'TEXT' | 'IMAGE' | 'VIDEO';

export type FeedItem = {
  id?: string;
  feedType?: FeedType;
  createdAt?: firebase.firestore.Timestamp;
  content: string;
  attachments: FeedPostAttachment[];
  userId: string;
  podcastId?: string;
  commentsCount?: number;
  allowComments?: boolean;
  isEmbedCode?: boolean;
};

export type User = {
  email: string;
  createdAt: firebase.firestore.Timestamp;
  userId: string;
  name?: string;
};
export type FeedComment = {
  id?: string;
  createdAt?: firebase.firestore.Timestamp;
  userName: string;
  userId?: string;
  content: string;
  feedId?: string;
  podcastId?: string;
  slug?: string;
};

export type LiveEventComment = {
  id?: string;
  createdAt?: firebase.firestore.Timestamp;
  userName: string;
  userId?: string;
  content: string;
  liveEventId?: string;
  podcastId?: string;
};

export type Commission = {
  podcastId: string;
  commission: number;
};

export type Transaction = {
  createdAt: moment.Moment;
  authcode?: string;
  podcastId: string;
  amount: number;
  isRenewal?: boolean;
  subscriptionId?: string;
};

export type ProductType = 'PREMIUM';

export type Product = {
  id: string;
  description: string;
  name: string;
  subscriptionPlans: SubscriptionPlan[];
  productType: ProductType;
  subscriptionType: 'RECURRING' | 'ONE_TIME';
};

export type ProductSubscription = {
  id?: string;
  borgunToken?: string;
  createdAt: firebase.firestore.Timestamp;
  endsAt?: firebase.firestore.Timestamp;
  subscriptionType: 'RECURRING' | 'ONE_TIME';
  userId: string;
  productId: string;
  authcode?: string;
  reference?: string;
  userEmail?: string;
  recurringToken?: string;
  preventRenewal?: boolean;
  lastRenewal?: firebase.firestore.Timestamp;
  subscriptionPlan: SubscriptionPlan;
  renewals?: string[];
  productType: ProductType;
};

export type RssLink = {
  id: string;
  token: string;
  podcastId: string;
  lastUsed: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
  useCount: number;
  userId: string;
  podcastOwnerId: string;
  active: boolean;
  link: string;
  userEmail: string;
  ips: string[];
  selectedProvider?: string;
};

export type LiveEvent = {
  id?: string;
  assets: {
    hls: string;
    iframe: string;
    player: string;
    thumbnail: string;
  };
  broadcasting: boolean;
  createdAt: firebase.firestore.Timestamp;
  liveStreamId: string;
  name: string;
  podcastId: string;
  public: boolean;
  record: boolean;
  streamKey?: string;
  updatedAt: firebase.firestore.Timestamp;
  userId: string;
};

export type AnalyticsItemType = {
  name: string;
  number: string | number;
  onlyAdmin?: boolean;
};
export type AnalyticsData = {
  activeSubscriptions: number;
  sales: AnalyticsItemType[];
  commissionNumbers: AnalyticsItemType[];
  subscriptions: AnalyticsItemType[];
};
