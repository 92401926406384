import React from 'react';
import s from './ManageLiveEvent.module.css';
import { LiveEvent, ModalType, Podcast } from '../../types';
import { LivePlayer } from '../live-player/LivePlayer';
import { CopyableInput } from '../copyable-input/CopyableInput';
import Switch from 'react-switch';
import firebase from 'firebase';
import { ToggleButton } from 'react-bootstrap';
import {
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
  useFirebase,
  useFirestore,
} from 'react-redux-firebase';
import LivesBusiness from '../../business/LivesBusiness';
import { LiveEventComments } from '../live-event-comments/LiveEventComments';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { LiveEventsInstruction } from '../live-events-instruction/LiveEventsInstruction';
import { EmptyIllustration } from '../empty-illustration/EmptyIllustration';
import { useTypedSelector } from '../../redux/reducers';
import PodcastBusiness from '../../business/PodcastBusiness';
type Props = {
  liveEvent: LiveEvent;
  bottomSpace?: boolean;
  podcast: Podcast;
};

const ToggleLiveButton: React.FC<{
  onToggle: () => void;
  checked: boolean;
}> = ({ onToggle, checked }) => {
  return (
    <div className={s.container}>
      <label htmlFor="material-switch">
        <span className={s.toggleLabel}>
          {checked ? 'Bein útsending' : 'Streymið er falið'}
        </span>
        <Switch
          checked={checked}
          onChange={onToggle}
          onColor="#7857C8"
          onHandleColor="#211148"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          className="react-switch"
          id="material-switch"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onToggle();
            }
          }}
        />
      </label>
    </div>
  );
};
export const ManageLiveEvent: React.FC<Props> = ({ podcast, liveEvent }) => {
  const embedIframeCode = `<iframe src="${liveEvent.assets.player}" width="100%" height="280" frameborder="0" scrolling="no" allowfullscreen="true"></iframe>`;
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const profile = useTypedSelector((state) => state.firestore.profile);
  const podcastBusiness = new PodcastBusiness(
    firestore,
    profile?.userId,
    profile?.email
  );
  return (
    <div className={s.container}>
      <div className={s.toggleComments}>
        <label htmlFor="material-switch">
          <span className={s.toggleLabel}>Athugasemdir</span>
          <Switch
            checked={!podcast.disableLiveStreamComments}
            onChange={async () => {
              if (podcast.disableLiveStreamComments) {
                await podcastBusiness.update({
                  id: podcast.id,
                  disableLiveStreamComments: false,
                });
              } else {
                await podcastBusiness.update({
                  id: podcast.id,
                  disableLiveStreamComments: true,
                });
              }
            }}
            onColor="#7857C8"
            onHandleColor="#211148"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
        </label>
        <a
          className={s.resetComments}
          href={'#'}
          onClick={async () =>
            await podcastBusiness.update({
              id: podcast.id,
              lastLiveStreamingStart:
                firebase.firestore.FieldValue.serverTimestamp() as any,
            })
          }
        >
          Endurstilla athugasemdir
        </a>
      </div>
      <div className={s.keys}>
        <CopyableInput
          label={'RTMP Server URL'}
          text={'rtmp://broadcast.api.video/s'}
          bottomSpace={true}
        />
        <CopyableInput
          label={'Auðkenni (Stream Key)'}
          text={liveEvent.streamKey || ''}
          bottomSpace={true}
        />
        <CopyableInput
          label={'Embed Kóði'}
          text={embedIframeCode}
          bottomSpace={true}
        />
      </div>
      <div className={s.obs}>
        Pardus mælir með{' '}
        <a href={'https://obsproject.com/'} target={'_blank'}>
          OBS Studio
        </a>{' '}
        fyrir beinar útsendingar.{' '}
        <a
          href={'#'}
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              showModal(ModalType.BASIC_MODAL, {
                content: <LiveEventsInstruction />,
              })
            );
          }}
        >
          Smelltu til að skoða leiðbeiningar.
        </a>
      </div>
      {!podcast.isLiveStreaming && (
        <div style={{ marginBottom: '20px' }}>
          <EmptyIllustration message={'Streymið er ekki sýnilegt.'} />
        </div>
      )}
      {podcast.isLiveStreaming && (
        <>
          <div className={s.isLiveText}>Bein útsending í gangi!</div>
          <LivePlayer src={liveEvent.assets.player} height={590} mute={true} />
        </>
      )}
      {!podcast.disableLiveStreamComments && (
        <div className={s.comments}>
          <LiveEventComments
            liveEventId={liveEvent.id as string}
            podcast={podcast}
          />
        </div>
      )}
    </div>
  );
};
