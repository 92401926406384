import firebase from 'firebase';
import clientConfig from '../client-config';
import { Podcast } from '../types';
import { slugify } from '../utils/utilities';

export default class PodcastBusiness {
  constructor(
    private db: firebase.firestore.Firestore,
    private userId: string,
    private userEmail: string
  ) {}
  async create(podcast: Podcast) {
    return this.db.collection('podcasts').add({
      ...podcast,
      active: true,
      status: 'DRAFT',
      userId: this.userId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      userEmail: this.userEmail,
      feedCount: 0,
      client: clientConfig.name,
      slug: slugify(podcast.name as string),
    });
  }

  async update(podcast: Podcast) {
    const podcastRef = this.db.collection('podcasts').doc(podcast.id);
    await podcastRef.update({
      ...podcast,
    });
  }
}
