import React from 'react';
import { HomepageVisirHeader } from '../../components/homepage-visir-header/HomepageVisirHeader';
import { PageContainer } from '../../components/page-container/PageContainer';
import { VisirTheme } from '../../visir-components/visir-theme/VisirTheme';
import { HomepageVisirList } from '../../components/homepage-visir-list/HomepageVisirList';
import { Redirect } from 'react-router';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useTypedSelector } from '../../redux/reducers';
import { Spinner } from '../../components/spinner/Spinner';
import { Center } from '../../components/position/Center';

const HomepageVisir: React.FC = (props) => {
  useFirestoreConnect({
    collection: 'publicsettings',
  });
  const data = useTypedSelector(
    (state) => state.firestore.ordered.publicsettings
  );
  if (!isLoaded(data) || !data.length) {
    return (
      <VisirTheme>
        <PageContainer>
          <Center>
            <Spinner />
          </Center>
        </PageContainer>
      </VisirTheme>
    );
  }
  if (isLoaded(data) && !data[0].enableFrontPage) {
    return (
      <Redirect
        to={
          localStorage?.getItem('home-path') ||
          '/' + process.env.REACT_APP_DEFAULT_PODCAST
        }
      />
    );
  }

  return (
    <VisirTheme>
      <PageContainer>
        <HomepageVisirHeader />
        <HomepageVisirList />
      </PageContainer>
    </VisirTheme>
  );
};
export default HomepageVisir;
