import { FeedComment, FeedItem } from '../types';
import firebase from 'firebase';
import { call } from '../utils/ApiManager';

export default class FeedBusiness {
  constructor(
    private db: firebase.firestore.Firestore,
    private userId: string
  ) {}
  async remove(feedId: string, podcastId: string) {
    await this.db
      .collection('podcasts')
      .doc(podcastId)
      .update({
        feedCount: firebase.firestore.FieldValue.increment(-1),
      });
    return this.db.collection('feeds').doc(feedId).delete();
  }

  async postComment(podcastId: string, feedId: string, comment: FeedComment) {
    await this.db.collection('feedcomments').add({
      ...comment,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      userId: this.userId,
      feedId,
      podcastId,
    });
  }

  async setUsername(name: string) {
    await call('/users/setusername')({ name });
  }

  async deleteComment(commentId: string) {
    const comment = await this.db.collection('feedcomments').doc(commentId);
    await comment.delete();
  }
}
