import React, { useState } from 'react';
import s from './UploadSoundFile.module.css';
import { dataURLtoFile, uuid } from '../../utils/utilities';
import firebase from 'firebase';
//@ts-ignore
import { useFirebase } from 'react-redux-firebase';
import { ManageEpisodePlayer } from '../manage-episode-player/ManageEpisodePlayer';
import { ProgressBar } from 'react-bootstrap';
import { useTypedSelector } from '../../redux/reducers';

type Props = {
  url: string;
  save: (url: string) => void;
};

export const UploadSoundFile: React.FC<Props> = ({ url, save }) => {
  const fireb = useFirebase();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const auth = useTypedSelector((state) => state.firebase.auth);
  const upload = async (content: string): Promise<string> => {
    return new Promise((resolve) => {
      let file = dataURLtoFile(content);
      //@ts-ignore
      let fileName = uuid() + '.mp3';
      const uploadTask = fireb
        .storage()
        .ref('/episodes/' + (auth?.uid + '/') + fileName)
        .put(file);
      setCurrentProgress(0);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: any) => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setCurrentProgress(Math.round(progress));
        },
        () => {},
        () => {
          resolve(
            `${process.env.REACT_APP_CDN_HOSTNAME}/episodes/${auth?.uid}/${fileName}`
          );
        }
      );
    });
  };

  const readFile = (files: any) => {
    if (!files.length) {
      return;
    }
    const file = files.item(0);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const content = reader.result;
      setIsLoading(true);
      const url = await upload(content as string);
      save(url);
      setIsLoading(false);
      //@ts-ignore
      document.getElementById('file-input').value = '';
    };
  };

  return (
    <>
      <div className={s.container}>
        {!url ? (
          <>
            {!isLoading && (
              <label htmlFor="file-input" className={s.selectFile}>
                Veldu hljóðskrá
              </label>
            )}
            {isLoading && (
              <ProgressBar
                now={currentProgress}
                label={`${currentProgress}%`}
              />
            )}
          </>
        ) : (
          <ManageEpisodePlayer
            reset={() => save('')}
            url={url}
            title={'Hljóðskrá'}
          />
        )}
      </div>
      <input
        className={s.hide}
        type="file"
        id="file-input"
        accept="audio/*"
        onChange={(e) => readFile(e.target.files)}
      />
    </>
  );
};
