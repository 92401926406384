import { yupResolver } from '@hookform/resolvers/yup';
import fb from 'firebase';
import React, { FC, PropsWithChildren, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useFirebase } from 'react-redux-firebase';
import * as yup from 'yup';
import { useTypedSelector } from '../../redux/reducers';
import { error, success } from '../../utils/Notifications';
import { Button } from '../button/Button';
import { FormInput } from '../form-fields/FormInput';
import s from './ChangePassword.module.css';
export const ChangePassword = ({ children }: PropsWithChildren<{}>) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const firebase = useFirebase();
  const [isLoading, setIsLoading] = useState(false);
  const onSave = async (
    oldPassword: string,
    newPassword: string,
    resetForm: () => void
  ) => {
    setIsLoading(true);
    var user = firebase.auth().currentUser;
    var cred = fb.auth.EmailAuthProvider.credential(
      auth.email as string,
      oldPassword
    );
    try {
      await user?.reauthenticateWithCredential(cred);
      await user?.updatePassword(newPassword);

      resetForm();
      success('Lykilorðið þitt hefur verið uppfært.');
    } catch (err: any) {
      if (
        err?.message ===
        'The password is invalid or the user does not have a password.'
      ) {
        error('Núverandi lykilorð er rangt.');
      } else {
        error(err.message || 'Tókst ekki að breyta lykilorði');
      }
    }
    setIsLoading(false);
  };
  const requiredMessage = 'Vinsamlegast fylltu út í þennan reit.';
  const schema = yup.object().shape({
    oldPassword: yup.string().required(requiredMessage),
    newPassword: yup
      .string()
      .required(requiredMessage)
      .min(6, 'Lykilorðið verður að vera minnst 6 stafir að lengd.'),
    newPasswordConfirm: yup
      .string()
      .test('passwords-match', 'Lykilorð passa ekki saman.', function (value) {
        return this.parent.newPassword === value;
      }),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  return (
    <div className={s.formContainer}>
      <form
        className={s.form}
        onSubmit={handleSubmit(
          (d) => onSave(d.oldPassword, d.newPassword, reset),
          (e) => console.log(e)
        )}
      >
        <Row>
          <Col lg={4}>
            <div className={s.input}>
              <FormInput
                label="Núverandi lykilorð"
                error={errors.oldPassword?.message}
                type="password"
                placeholder="Skrifaðu lykilorðið þitt"
                {...register('oldPassword')}
              />
            </div>
          </Col>
          <Col lg={4}>
            <div className={s.input}>
              <FormInput
                label="Nýtt lykilorð (minnst 6 stafir)"
                error={errors.newPassword?.message}
                type="password"
                placeholder="Skrifaðu lykilorðið þitt"
                {...register('newPassword')}
              />
            </div>
          </Col>
          <Col lg={4}>
            <div className={s.input}>
              <FormInput
                label="Lykilorð aftur"
                error={errors.newPasswordConfirm?.message}
                type="password"
                placeholder="Skrifaðu lykilorðið þitt aftur"
                {...register('newPasswordConfirm')}
              />
            </div>
          </Col>
        </Row>
        <div className={s.submit}>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Hinkraðu...' : 'Vista'}
          </Button>
        </div>
      </form>
    </div>
  );
};
