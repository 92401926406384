import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'video-react/dist/video-react.css';
import { useFirestore } from 'react-redux-firebase';
import { useTypedSelector } from '../../redux/reducers';
import { FeedItem, FeedType } from '../../types';
import s from './PodcastFeed.module.css';
//@ts-ignore
import { Player } from 'video-react';
import { FeedPost } from '../feed-post/FeedPost';
import firebase from 'firebase/app';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PodcastFeedHeader } from './PodcastFeedHeader';
import cn from 'classnames';
import clientConfig from '../../client-config';

type Props = {
  podcastId: string;
  slug: string;
};

export const PodcastFeed: React.FC<Props> = ({ podcastId, slug }) => {
  const [feeds, setFeeds] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [feedType, setFeedType] = useState<FeedType | null>(null);
  const pageSize = 3;
  const db = useFirestore();
  const fetchFeeds = () => {
    setHasMore(true);
    const query = !feedType
      ? db
          .collection('feeds')
          .where('podcastId', '==', podcastId)
          .orderBy('createdAt', 'desc')
          .limit(pageSize)
      : db
          .collection('feeds')
          .where('podcastId', '==', podcastId)
          .where('feedType', '==', feedType)
          .orderBy('createdAt', 'desc')
          .limit(pageSize);
    return query.onSnapshot((snapshot) => {
      setFeeds([...snapshot.docs]);
    });
  };
  const fetchMore = async (lastVisible: any) => {
    const query = !feedType
      ? db
          .collection('feeds')
          .where('podcastId', '==', podcastId)
          .orderBy('createdAt', 'desc')
          .startAfter(lastVisible)
          .limit(pageSize)
      : db
          .collection('feeds')
          .where('podcastId', '==', podcastId)
          .where('feedType', '==', feedType)
          .orderBy('createdAt', 'desc')
          .startAfter(lastVisible)
          .limit(pageSize);
    const res = await query.get();
    if (res.docs.length < pageSize) {
      setHasMore(false);
    }
    setFeeds([...feeds, ...res.docs]);
  };
  useEffect(() => {
    const unsubscribe = fetchFeeds();
    return () => unsubscribe();
  }, [feedType]);
  const filteredFeeds = feeds.filter(
    (f: any) => f.data().podcastId === podcastId
  );
  const auth = useTypedSelector((state) => state.firebase.auth);
  const lastUpdated =
    !!feeds?.length &&
    feeds[0].data().createdAt &&
    !moment(feeds[0].data().createdAt.toDate()).isAfter(moment())
      ? 'Uppfært ' + moment(feeds[0].data().createdAt?.toDate()).fromNow()
      : '';
  if (!feeds.length) {
    return null;
  }
  return (
    <div>
      {clientConfig.name === 'visir' && (
        <PodcastFeedHeader
          setFeedType={setFeedType}
          feedType={feedType}
          metaText={lastUpdated}
          slug={slug}
        />
      )}
      {auth.isLoaded &&
        filteredFeeds
          .map((item: any) => ({ id: item.id, ...item.data() }))
          .map((f: FeedItem, index: number) => (
            <InfiniteScroll
              scrollThreshold={'200px'}
              dataLength={feeds.length}
              next={() => fetchMore(feeds ? feeds[feeds.length - 1] : null)}
              hasMore={hasMore}
              loader={null}
            >
              <FeedPost
                key={index}
                slug={slug}
                authorId={f.userId}
                podcastId={podcastId}
                attachments={f.attachments}
                createdAt={f.createdAt as firebase.firestore.Timestamp}
                content={f.content}
                isEmbedCode={f.isEmbedCode}
                allowComments={f.allowComments}
                feedId={f.id}
              />
            </InfiniteScroll>
          ))}
    </div>
  );
};
