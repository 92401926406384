import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './redux/reducers';

export default function configureStore(persistedState: any) {
  return createStore(
    rootReducer,
    persistedState,
    applyMiddleware(thunkMiddleware)
  );
}
